<template>
  <div class="dizme_tm_section">
    <div class="dizme_tm_copyright">
      <div class="container">
        <div class="inner">
          <div class="left wow fadeInLeft" data-wow-duration="1s">
            <a href="#">
              <img
                :src="`img/logo/${dark ? 'dark' : 'stephan'}.png`"
                alt=""
                width="300"
              />
            </a>
            <!-- <p>Stephan Westermayer &copy; {{ new Date().getFullYear() }}</p> -->
          </div>
          <div class="right wow fadeInRight" data-wow-duration="1s">
            <p style="margin-bottom: 10px; margin-top: 0; color: #716196">
              Pages
            </p>
            <ul>
              <li><a href="#">Home</a></li>
              <li><a href="#vision">Vision</a></li>
              <li><a href="#contact">Contact</a></li>
            </ul>
          </div>
          <div class="right wow fadeInRight" data-wow-duration="1s">
            <p style="margin-bottom: 10px; margin-top: 0; color: #716196">
              Links
            </p>
            <ul>
              <li style="margin-bottom: 5px">
                <a href="https://bluepalm.group/" target="_blank">Blue Palm Group</a>
              </li>
              <li style="margin-bottom: 5px">
                <a href="https://bluepalm.rentals/" target="_blank">Blue Palm Hosting Services</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CopyrightComponent",
  components: {},
};
</script>
<style scoped>
.right ul li {
  margin-top: 0;
}
</style>
